import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmailDTO, NewUserDTO } from 'src/core/auth/services/dto';
import { Services } from 'src/core/common/context';
import { FlowName } from 'src/core/quiz/entities';
import { ZodiacTypes } from 'src/core/zodiac/entities';
import { normalizeError } from 'src/core/common/utils/errors';
import { AccountExistError } from 'src/core/common/errors';
import { AppThunk } from 'src/core/common/store';
import { RootInitialState } from 'src/core/common/store/rootReducer';
import {
  SignInByTokenFailedEvent,
  SignInByTokenPendingEvent,
  SignInByTokenSucceedEvent,
} from 'src/core/auth/analytics/events';
import { generateUuid } from 'src/core/common/utils/generateUuid';
import { SignInTokenMissedError } from 'src/core/user/errors';
import { SpanStatusCode } from 'src/core/common/observability/entities';
import { getUtmTags } from '../selectors';
import { getUserPreferences, getZodiac } from './selectors';
import {
  requestPasswordResetFulfilled,
  requestPasswordResetPending,
  signInFailed,
  signInFulfilled,
  signInPending,
  updateEmailFulfilled,
  updateEmailPending,
} from './actions';

export const signInByToken = createAsyncThunk<
  string | null,
  string | undefined,
  { extra: Services }
>('user/SIGN_IN_BY_TOKEN', async (token, { extra, rejectWithValue }) => {
  const {
    authService,
    analyticsService,
    sessionRecorder,
    logger,
    observabilitySystem,
    userService,
    clarityHeatmapRecordingService,
  } = extra;
  const span = observabilitySystem?.startSpan('sign_in_by_token');
  span.addEvent('Pending');

  if (!token) {
    span.addEvent('Failed');
    span.recordException('No Token');
    span.setStatus({ code: SpanStatusCode.ERROR, message: 'No Token' });

    const error = new SignInTokenMissedError();

    analyticsService.track(new SignInByTokenFailedEvent({ error }));
    logger.error(error);
    return rejectWithValue(error.message);
  }

  try {
    analyticsService.track(new SignInByTokenPendingEvent());

    const { user } = await authService.signInByToken(token);
    const { email } = await userService.getUser();
    analyticsService.setUserId(user.id);
    sessionRecorder.identifyUser(user.id);
    analyticsService.track(new SignInByTokenSucceedEvent());
    clarityHeatmapRecordingService.addCustomTag('email', email);

    span.addEvent('Succeed');
    return user.id;
  } catch (e) {
    const err = normalizeError(e);
    logger.error(err);
    analyticsService.track(new SignInByTokenFailedEvent({ error: err }));
    span.addEvent('Failed');
    span.recordException(err);
    span.setStatus({ code: SpanStatusCode.ERROR, message: err.message });
    return rejectWithValue(err.message);
  } finally {
    span.end();
  }
});

export const signUpViaGoogle = createAsyncThunk<
  { id: string; email: string },
  { token: string; quizName: FlowName; email: string },
  { extra: Services; state: RootInitialState }
>(
  'user/SIGN_UP_VIA_GOOGLE',
  async ({ token, quizName, email }, { extra, rejectWithValue, getState }) => {
    const {
      authService,
      analyticsService,
      sessionRecorder,
      crossDomainStorage,
      observabilitySystem,
      clarityHeatmapRecordingService,
    } = extra;
    const span = observabilitySystem?.startSpan('sign_up_via_google');

    const userPreferences = getUserPreferences(getState());
    const zodiac = getZodiac(getState());
    const utmTags = getUtmTags(getState());
    const attributionParams = analyticsService.getAttributionParameters();

    const sessionId = await analyticsService.getSessionId();
    const deviceId = await analyticsService.getDeviceId();
    const isOptOutSellUserData = crossDomainStorage.isOptOutSellUserData();

    analyticsService.lead();

    const index = email.indexOf('@');
    const userName = email.slice(0, index);
    const userData: Omit<NewUserDTO, 'email'> = {
      type: 'google',
      token,
      name: userName,
      gender: userPreferences.gender || 'female',
      birth_day: userPreferences.date || '1990-01-01',
      birth_time: null,
      birth_place: null,
      longitude: '0.05',
      latitude: '51.45',
      extra_data: {
        ...userPreferences,
      },
      acquisition_source: quizName,
      acquisition_data: {
        email_consent: false,
        gender: userPreferences.gender || 'female',
        partnerGender: userPreferences.partnerGender || 'male',
        zodiacSign: zodiac || ZodiacTypes.CAPRICORN,
        partnerZodiacSign: ZodiacTypes.CAPRICORN,
      },
      media_source: utmTags.utm_source,
      quiz: utmTags.quizz,
      campaign_id: utmTags.utm_campaignid,
      campaign: utmTags.utm_campaign,
      adset_id: utmTags.utm_adsetid,
      adset: utmTags.utm_adset,
      ad_id: utmTags.utm_adid,
      ad: utmTags.utm_ad,
      gclid: utmTags.gclid,
      fbclid: utmTags.fbclid,
      fb_test_event_code: utmTags.fb_test_event_code,
      sessionId,
      deviceId,
      do_not_sell_data: isOptOutSellUserData,
      ...attributionParams,
    };
    try {
      span.addEvent('Pending');
      const authResult = await authService.signUpViaExternal(userData);

      await crossDomainStorage.saveLastUserEmail(email);
      analyticsService.setUserId(authResult.user.id);
      analyticsService.accountCreated(authResult.user.id);
      sessionRecorder.identifyUser(authResult.user.id, {
        email,
        gender: userData.gender,
        acquisition_source: quizName,
      });
      clarityHeatmapRecordingService.addCustomTag('email', email);
      span.addEvent('Succeed');
      return { email, id: authResult.user.id };
    } catch (err) {
      const errorMessage =
        err instanceof AccountExistError ? 'Account exists' : 'Something went wrong';
      span.addEvent('Failed');
      span.recordException(errorMessage);
      span.setStatus({ code: SpanStatusCode.ERROR, message: errorMessage });
      return rejectWithValue(errorMessage);
    } finally {
      span.end();
    }
  },
);

export const signUpViaFacebook = createAsyncThunk<
  { id: string; email?: string },
  { token: string; quizName: FlowName; email?: string },
  { extra: Services; state: RootInitialState }
>(
  'user/SIGN_UP_VIA_FACEBOOK',
  async ({ token, quizName, email }, { extra, rejectWithValue, getState }) => {
    const {
      authService,
      analyticsService,
      sessionRecorder,
      crossDomainStorage,
      userService,
      observabilitySystem,
      clarityHeatmapRecordingService,
    } = extra;
    const span = observabilitySystem?.startSpan('sign_up_via_facebook');

    const userPreferences = getUserPreferences(getState());
    const zodiac = getZodiac(getState());
    const utmTags = getUtmTags(getState());
    const attributionParams = analyticsService.getAttributionParameters();

    const sessionId = await analyticsService.getSessionId();
    const deviceId = await analyticsService.getDeviceId();
    const isOptOutSellUserData = crossDomainStorage.isOptOutSellUserData();

    analyticsService.lead();

    let userName = 'user' + generateUuid();

    if (email) {
      const index = email.indexOf('@');
      userName = email.slice(0, index);
    }

    const userData: Omit<NewUserDTO, 'email'> = {
      type: 'facebook',
      token,
      name: userName,
      gender: userPreferences.gender || 'female',
      birth_day: userPreferences.date || '1990-01-01',
      birth_time: null,
      birth_place: null,
      longitude: '0.05',
      latitude: '51.45',
      extra_data: {
        ...userPreferences,
      },
      acquisition_source: quizName,
      acquisition_data: {
        email_consent: false,
        gender: userPreferences.gender || 'female',
        partnerGender: userPreferences.partnerGender || 'male',
        zodiacSign: zodiac || ZodiacTypes.CAPRICORN,
        partnerZodiacSign: ZodiacTypes.CAPRICORN,
      },
      media_source: utmTags.utm_source,
      quiz: utmTags.quizz,
      campaign_id: utmTags.utm_campaignid,
      campaign: utmTags.utm_campaign,
      adset_id: utmTags.utm_adsetid,
      adset: utmTags.utm_adset,
      ad_id: utmTags.utm_adid,
      ad: utmTags.utm_ad,
      gclid: utmTags.gclid,
      fbclid: utmTags.fbclid,
      sessionId,
      deviceId,
      do_not_sell_data: isOptOutSellUserData,
      ...attributionParams,
    };
    try {
      span.addEvent('Pending');
      const authResult = await authService.signUpViaExternal(userData);

      const { email: userEmail } = await userService.getUser();

      let fbEmail = '';

      if (!!userEmail) {
        fbEmail = userEmail.includes('fb-user') ? '' : userEmail;
      }

      analyticsService.setUserId(authResult.user.id);
      analyticsService.accountCreated(authResult.user.id);
      sessionRecorder.identifyUser(authResult.user.id, {
        gender: userData.gender,
        acquisition_source: quizName,
      });
      clarityHeatmapRecordingService.addCustomTag('email', email ?? fbEmail);

      span.addEvent('Succeed');
      return { email: email ?? fbEmail, id: authResult.user.id };
    } catch (err) {
      const errorMessage =
        err instanceof AccountExistError ? 'Account exists' : 'Something went wrong';
      span.addEvent('Failed');
      span.recordException(errorMessage);
      span.setStatus({ code: SpanStatusCode.ERROR, message: errorMessage });
      return rejectWithValue(errorMessage);
    } finally {
      span.end();
    }
  },
);

export const signUpViaQuiz = createAsyncThunk<
  { id: string; email: string },
  { email: string; quizName: FlowName; emailConsent?: boolean; password?: string },
  { extra: Services; state: RootInitialState }
>(
  'user/SIGN_UP_VIA_QUIZ',
  async ({ emailConsent, password, quizName, email }, { extra, getState, rejectWithValue }) => {
    const {
      authService,
      analyticsService,
      sessionRecorder,
      crossDomainStorage,
      observabilitySystem,
      clarityHeatmapRecordingService,
    } = extra;
    const span = observabilitySystem?.startSpan('sign_up_via_quiz', {
      attributes: {
        quizName,
        email,
      },
    });

    const userPreferences = getUserPreferences(getState());
    const zodiac = getZodiac(getState());
    const utmTags = getUtmTags(getState());
    const attributionParams = analyticsService.getAttributionParameters();

    const sessionId = await analyticsService.getSessionId();
    const deviceId = await analyticsService.getDeviceId();
    const isOptOutSellUserData = crossDomainStorage.isOptOutSellUserData();

    analyticsService.lead();

    const index = email.indexOf('@');
    const userName = email.slice(0, index);

    const userData: NewUserDTO = {
      email,
      password,
      name: userPreferences.name || userName,
      gender: userPreferences.gender || 'female',
      birth_day: userPreferences.date || '1990-01-01',
      birth_time: null,
      birth_place: null,
      longitude: '0.05',
      latitude: '51.45',
      extra_data: {
        ...userPreferences,
      },
      acquisition_source: quizName,
      acquisition_data: {
        email_consent: emailConsent || false,
        gender: userPreferences.gender || 'female',
        partnerGender: userPreferences.partnerGender || 'male',
        zodiacSign: zodiac || ZodiacTypes.CAPRICORN,
        partnerZodiacSign: ZodiacTypes.CAPRICORN,
      },
      media_source: utmTags.utm_source,
      quiz: utmTags.quizz,
      campaign_id: utmTags.utm_campaignid,
      campaign: utmTags.utm_campaign,
      adset_id: utmTags.utm_adsetid,
      adset: utmTags.utm_adset,
      ad_id: utmTags.utm_adid,
      ad: utmTags.utm_ad,
      gclid: utmTags.gclid,
      fbclid: utmTags.fbclid,
      fb_test_event_code: utmTags.fb_test_event_code,
      sessionId,
      deviceId,
      do_not_sell_data: isOptOutSellUserData,
      ...attributionParams,
    };
    try {
      span.addEvent('Pending');
      const authResult = (await authService.signUpViaQuiz(userData)) as EmailDTO;

      await crossDomainStorage.saveLastUserEmail(email);
      analyticsService.setUserId(authResult.user.id);
      analyticsService.accountCreated(authResult.user.id);
      sessionRecorder.identifyUser(authResult.user.id, {
        email,
        gender: userData.gender,
        acquisition_source: quizName,
      });
      clarityHeatmapRecordingService.addCustomTag('email', email);

      span.addEvent('Succeed');
      return { email, id: authResult.user.id };
    } catch (err) {
      const errorMessage =
        err instanceof AccountExistError ? 'Account exists' : 'Something went wrong';

      span.addEvent('Failed');
      span.recordException(errorMessage);
      span.setStatus({ code: SpanStatusCode.ERROR, message: errorMessage });

      return rejectWithValue(errorMessage);
    } finally {
      span.end();
    }
  },
);

export const signIn =
  (credentials: { email: string; password: string }): AppThunk =>
  async (
    dispatch,
    getState,
    { authService, analyticsService, logger, observabilitySystem, clarityHeatmapRecordingService },
  ) => {
    const { email, password } = credentials;
    const span = observabilitySystem.startSpan('sign_in');

    try {
      span.addEvent('Pending');
      dispatch(signInPending());

      const { user } = await authService.signIn(email, password);

      analyticsService.setUserId(user.id);

      dispatch(signInFulfilled());
      clarityHeatmapRecordingService.addCustomTag('email', email);

      span.addEvent('Succeed');
    } catch (e) {
      const err = normalizeError(e);
      logger.error(err);

      dispatch(signInFailed(err.message));
      span.addEvent('Failed');
      span.recordException(err);
      span.setStatus({ code: SpanStatusCode.ERROR, message: err.message });

      throw e;
    } finally {
      span.end();
    }
  };

export const requestPasswordReset =
  (data: { email: string }): AppThunk =>
  async (dispatch, getState, { authService, logger, observabilitySystem }) => {
    const { email } = data;
    const span = observabilitySystem.startSpan('request_password_reset', { attributes: { email } });

    try {
      span.addEvent('Pending');
      dispatch(requestPasswordResetPending());

      await authService.requestPasswordReset(email);

      dispatch(requestPasswordResetFulfilled());
      span.addEvent('Succeed');
    } catch (e) {
      const err = normalizeError(e);
      logger.error(err);
      span.addEvent('Failed');
      span.recordException(err);
      span.setStatus({ code: SpanStatusCode.ERROR, message: err.message });
    } finally {
      span.end();
    }
  };

export const updateEmail =
  (data: { email: string }): AppThunk =>
  async (
    dispatch,
    getState,
    { userService, logger, observabilitySystem, clarityHeatmapRecordingService },
  ) => {
    const { email } = data;
    const span = observabilitySystem.startSpan('update_email');

    try {
      span.addEvent('Pending');
      dispatch(updateEmailPending());

      await userService.changeEmail(email);

      dispatch(updateEmailFulfilled({ email }));
      clarityHeatmapRecordingService.addCustomTag('email', email);
      span.addEvent('Succeed');
    } catch (e) {
      const err = normalizeError(e);
      logger.error(err);
      span.addEvent('Failed');
      span.recordException(err);
      span.setStatus({ code: SpanStatusCode.ERROR, message: err.message });
    } finally {
      span.end();
    }
  };
