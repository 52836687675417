import { Logger } from 'src/core/common/logger/interfaces';
import {
  ClarityFunction,
  ClarityHeatmapRecordingService,
  CustomTagUnion,
  IdentifyUserOptions,
} from './ClarityHeatmapRecordingService';

function createClarityFunction(): ClarityFunction {
  return function (): void {
    return;
  };
}
export class ClarityHeatmapRecordingClientService implements ClarityHeatmapRecordingService {
  constructor(private logger: Logger) {}

  private get track(): ClarityFunction {
    if (!window.clarity) {
      this.logger.warn('Clarity is not initialized');
      return createClarityFunction;
    }
    return window.clarity;
  }

  identifyUser(options: IdentifyUserOptions) {
    this.track(
      'identify',
      options.customId,
      options.customSessionId,
      options.customPageId,
      options.friendlyName,
    );
  }
  giveCookieConsent() {
    this.track('consent');
  }

  triggerCustomEvent(value: string) {
    this.track('event', value);
  }

  addCustomTag(key: CustomTagUnion, value: string | [string, string]) {
    this.track('set', key, value);
  }

  markImportantSession(reason: string) {
    this.track('upgrade', reason);
  }

  abort() {
    this.identifyUser({ customId: 'Unsubscribe' });
    this.triggerCustomEvent('Unsubscribe');
    delete window.clarity;
  }
}
