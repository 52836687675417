globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"tow6ZyymE7y1ie_LRaBkZ"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { init } from '@sentry/nextjs';
import { config } from 'src/config';
import packageJson from './package.json';

init({
  dsn: config.sentryDsn,
  tracesSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  release: packageJson.version,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  ignoreErrors: [
    "Cannot read properties of undefined (reading 'domInteractive')",
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'Invalid token',
    'Failed to export with XHR (status: 0)',
  ],
  integrations: function (integrations) {
    // integrations will be all default integrations
    return integrations.filter(function (integration) {
      return integration.name !== 'BrowserTracing';
    });
  },
});
