import { createReducer } from '@reduxjs/toolkit';
import { LoadingStatuses } from 'src/core/common/entities';
import { AreaOfGuidance } from 'src/core/quiz/entities';
import {
  signInByTokenReset,
  signInFailed,
  signInFulfilled,
  signInPending,
  updateEmailFailed,
  updateEmailFulfilled,
  updateEmailPending,
  updateUserPreferences,
} from './actions';
import { UserState } from './state';
import { signInByToken, signUpViaFacebook, signUpViaGoogle, signUpViaQuiz } from './thunks';

export const userInitialState: UserState = {
  signInByTokenStatus: LoadingStatuses.IDLE,
  gender: '',
  goal: '',
  date: '',
  decisions: '',
  place: ['', ''],
  location: null,
  time: null,
  name: '',
  partnerGender: '',
  partnerDate: '',
  partnerPlace: ['', ''],
  partnerLocation: null,
  partnerTime: null,
  email: '',
  id: '',
  signUpError: '',
  signInError: '',
  password: '',
  signUpByGoogleStatus: LoadingStatuses.IDLE,
  signUpByFacebookStatus: LoadingStatuses.IDLE,
  registrationStatus: LoadingStatuses.IDLE,
  signInLoadingStatus: LoadingStatuses.IDLE,
  emailUpdateStatus: LoadingStatuses.IDLE,
  emailAlreadyExists: false,
  topicToDiscuss: AreaOfGuidance.FUTURE_TELLING,
  typeOfReading: {
    text: '',
    value: '',
  },
  funnel: '',
};

export const userReducer = createReducer(userInitialState, (builder) => {
  builder
    .addCase(updateUserPreferences, (state, { payload }) => ({
      ...state,
      [payload.preferenceName]: payload.preferenceValue,
    }))
    .addCase(signInByToken.pending, (state) => {
      state.signInByTokenStatus = LoadingStatuses.PENDING;
    })
    .addCase(signInByToken.rejected, (state) => {
      state.signInByTokenStatus = LoadingStatuses.FAILED;
    })
    .addCase(signInByToken.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.signInByTokenStatus = LoadingStatuses.FULFILLED;
      state.id = payload;
    })
    .addCase(signInByTokenReset, (state) => {
      state.signInByTokenStatus = LoadingStatuses.IDLE;
    })
    .addCase(signUpViaGoogle.pending, (state) => ({
      ...state,
      signUpError: '',
      registrationStatus: LoadingStatuses.IDLE,
      signUpByGoogleStatus: LoadingStatuses.PENDING,
    }))
    .addCase(signUpViaGoogle.rejected, (state, action) => ({
      ...state,
      signUpError: action.payload as string,
      signUpByGoogleStatus: LoadingStatuses.FAILED,
    }))
    .addCase(signUpViaGoogle.fulfilled, (state, { payload }) => ({
      ...state,
      email: payload.email,
      id: payload.id,
      signUpByGoogleStatus: LoadingStatuses.FULFILLED,
      registrationStatus: LoadingStatuses.FULFILLED,
    }))
    .addCase(signUpViaFacebook.pending, (state) => ({
      ...state,
      signUpError: '',
      registrationStatus: LoadingStatuses.IDLE,
      signUpByFacebookStatus: LoadingStatuses.PENDING,
    }))
    .addCase(signUpViaFacebook.rejected, (state, action) => ({
      ...state,
      signUpError: action.payload as string,
      signUpByFacebookStatus: LoadingStatuses.FAILED,
    }))
    .addCase(signUpViaFacebook.fulfilled, (state, { payload }) => ({
      ...state,
      email: payload.email || '',
      id: payload.id,
      signUpByFacebookStatus: LoadingStatuses.FULFILLED,
      registrationStatus: LoadingStatuses.FULFILLED,
    }))
    .addCase(signUpViaQuiz.pending, (state) => ({
      ...state,
      signUpError: '',
      signUpByGoogleStatus: LoadingStatuses.IDLE,
      registrationStatus: LoadingStatuses.PENDING,
    }))
    .addCase(signUpViaQuiz.rejected, (state, action) => {
      return {
        ...state,
        signUpError: action.payload as string,
        registrationStatus: LoadingStatuses.FAILED,
      };
    })
    .addCase(signUpViaQuiz.fulfilled, (state, { payload }) => ({
      ...state,
      email: payload.email,
      id: payload.id,
      signUpError: '',
      registrationStatus: LoadingStatuses.FULFILLED,
    }))
    .addCase(signInPending, (state) => {
      state.signInLoadingStatus = LoadingStatuses.PENDING;
      state.signInError = '';
    })
    .addCase(signInFulfilled, (state) => {
      state.signInLoadingStatus = LoadingStatuses.FULFILLED;
      state.signInError = '';
    })
    .addCase(signInFailed, (state, { payload }) => {
      state.signInLoadingStatus = LoadingStatuses.FAILED;
      state.signInError = payload as string;
    })
    .addCase(updateEmailPending, (state) => {
      state.emailUpdateStatus = LoadingStatuses.PENDING;
    })
    .addCase(updateEmailFailed, (state) => {
      state.emailUpdateStatus = LoadingStatuses.FAILED;
    })
    .addCase(updateEmailFulfilled, (state, { payload }) => {
      state.email = payload.email;
      state.emailUpdateStatus = LoadingStatuses.FULFILLED;
    });
});
