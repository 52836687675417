export enum QUIZ_ANALYTIC_EVENTS {
  FLOW_START_OPEN = 'flow_start_open',
  FLOW_INTRO_ACCEPT_COOKIES = 'flow_intro_accept_cookies',
  FLOW_GOAL_SETUP = 'flow_goal_setup',
  FLOW_GENDER_OPEN = 'flow_gender_open',
  FLOW_HOW_OPEN = 'flow_how_open',
  FLOW_BIRTH_DATE_OPEN = 'flow_birth_date_open',
  FLOW_CITY_OPEN = 'flow_city_open',
  FLOW_BIRTH_TIME_OPEN = 'flow_birth_time_open',
  FLOW_PROCESSING_PAGE_OPEN = 'flow_processing_page_open',
  FLOW_BEWARE_PAGE_OPEN = 'flow_beware_page_open',
  FLOW_TEST_TRUST_OPEN = 'flow_test_trust_open',
  FLOW_TEST_MAD_OPEN = 'flow_test_mad_open',
  FLOW_TEST_WRITE_OPEN = 'flow_test_write_open',
  FLOW_TEST_FRIENDS_OPEN = 'flow_test_friends_open',
  FLOW_TEST_COLOR_OPEN = 'flow_test_color_open',
  FLOW_TEST_OVERTHINK_OPEN = 'flow_test_overthink_open',
  FLOW_TEST_ELEMENT_OPEN = 'flow_test_element_open',
  FLOW_TEST_DATING_OPEN = 'flow_test_dating_open',
  FLOW_TEST_IMPORTANT_OPEN = 'flow_test_important_open',
  FLOW_COUPLE_STAT_PAGE_OPEN = 'flow_couple_stat_page_open',
  FLOW_PARTNER_GENDER_OPEN = 'flow_partner_gender_open',
  FLOW_PARTNER_BIRTH_DATE_OPEN = 'flow_partner_birth_date_open',
  FLOW_PARTNER_CITY_OPEN = 'flow_partner_city_open',
  FLOW_PARTNER_BIRTH_TIME_OPEN = 'flow_partner_birth_time_open',
  FLOW_PARTNER_BADQUALITY = 'flow_partner_badquality',
  FLOW_COMBINATION_PAGE_OPEN = 'flow_combination_page_open',
  FLOW_PARTNER_ATTITUDE_OPEN = 'flow_partner_attitude_open',
  FLOW_PARTNER_STRAIGHTFORWARD_OPEN = 'flow_partner_straightforward_open',
  FLOW_PARTNER_FINAL_DECISION = 'flow_partner_final_decision',
  FLOW_PARTNER_BIGPICTURE = 'flow_partner_bigpicture',
  FLOW_PARTNER_WORDS = 'flow_partner_words',
  FLOW_PARTNER_INTROVERT = 'flow_partner_introvert',
  FLOW_EMAILFORM_OPEN = 'flow_emailform_open',
  FLOW_EMAILCONSENT_OPEN = 'flow_emailconsent_open',
  FLOW_LANDING_PAGE_REDIRECT = 'flow_landing_page_redirect',
  FLOW_NAME_OPEN = 'flow_name_open',
  FLOW_TESTIMONIAL_OPEN = 'flow_testimonial_open',
  FLOW_INTERESTS_OPEN = 'flow_interests_open',
  FLOW_QUALITY_OPEN = 'flow_quality_open',
  FLOW_DETAILS_OPEN = 'flow_details_open',
  FLOW_PARTNER_OPEN = 'flow_partner_open',
  REGISTRATION_SCREEN_OPEN = 'registration_screen_open',
  REGISTRATION_GOAL_OPEN = 'registration_goal_open',
  REGISTRATION_REVIEWS_OPEN = 'registration_reviews_open',
  REGISTRATION_GENDER_OPEN = 'registration_gender_open',
  REGISTRATION_APPROACH_OPEN = 'registration_approach_open',
  REGISTRATION_BIRTHDATE_OPEN = 'registration_birthdate_open',
  REGISTRATION_BIRTHDATE_CLICK = 'registration_birthdate_click',
  REGISTRATION_STATEMENT_OPEN = 'registration_statement_open',
  REGISTRATION_NOT_GOOD_ENOUGH_OPEN = 'registration_not_good_enough_open',
  REGISTRATION_SPIRITUALITY_BELIEF_OPEN = 'registration_spirituality_belief_open',
  REGISTRATION_SPIRITUALITY_KNOWLEDGE_OPEN = 'registration_spirituality_knowledge_open',
  REGISTRATION_SPIRITUALITY_READING_OPEN = 'registration_spiritual_reading_open',
  REGISTRATION_PURPOSE_OPEN = 'registration_purpose_open',
  REGISTRATION_INNER_SELF_OPEN = 'registration_inner_self_open',
  REGISTRATION_DECISIONS_OPEN = 'registration_decisions_open',
  REGISTRATION_EXPECTATIONS_OPEN = 'registration_expectations_open',
  REGISTRATION_EXPECTATIONS_CLICK = 'registration_expectations_click',
  REGISTRATION_EXPERT_TRAITS_OPEN = 'registration_expert_traits_open',
  REGISTRATION_EXPERT_TRAITS_CLICKED = 'registration_expert_traits_click',
  REGISTRATION_EXPERT_TRAITS_OTHER_OPEN = 'registrations_expert_traits_custom_form_open',
  REGISTRATION_EXPERT_TRAITS_OTHER_CLICK = 'registrations_expert_traits_custom_form_click',
  REGISTRATION_EMIALFORM_OPEN = 'registration_emailform_open',
  REGISTRATION_DATA_OPEN = 'registration_data_open',
  REGISTRATION_PROCESSING_PAGE_OPEN = 'registration_processing_page_open',
  REGISTRATION_EMIAL_CONFIRM_OPEN = 'registration_email_consent_open',
  REGISTRATION_OFFER_OPEN = 'registration_offer_open',
  REGISTRATION_PASSWORD_OPEN = 'registration_password_open',
  REGISTRATION_EXAMPLES_OPEN = 'registration_examples_open',
  REGISTRATION_EXAMPLES_CLICK = 'registration_examples_click',

  SUBSCRIPTION_EMAILFORM_OPEN = 'subscription_emailform_open',
  SUBSCRIPTION_PASSWORD_OPEN = 'subscription_password_open',
  SUBSCRIPTION_NAME_OPEN = 'subscription_name_open',
  SUBSCRIPTION_ANIMATION_OPEN = 'subscription_animation_open',
  SUBSCRIPTION_PERKS_OPEN = 'subscription_perks_open',
  SUBSCRIPTION_HIGHLIGHTS_OPEN = 'subscription_highlights_open',
  CATALOGUE_FEELING_OPEN = 'catalogue_feeling_open',
  CATALOGUE_TOPIC_OPEN = 'catalogue_topic_open',
  CATALOGUE_SKILL_OPEN = 'catalogue_skill_open',
  CATALOGUE_ADVICE_OPEN = 'catalogue_advice_open',
  CATALOGUE_NAME_BIRTHDATE_OPEN = 'catalogue_name_birthdate_open',
  REGISTRATION_GENDER_CLICKED = 'registration_gender_clicked',
  REGISTRATION_NOT_GOOD_ENOUGH_CLICKED = 'registration_not_good_enough_clicked',
  REGISTRATION_SPIRITUALITY_BELIEF_CLICKED = 'registration_spirituality_belief_clicked',
  REGISTRATION_SPIRITUALITY_KNOWLEDGE_CLICKED = 'registration_spirituality_knowledge_clicked',
  REGISTRATION_SPIRITUALITY_READING_CLICKED = 'registration_spiritual_reading_clicked',
  REGISTRATION_PURPOSE_CLICKED = 'registration_purpose_clicked',
  REGISTRATION_CONSCIOUSNESS_LEVELS_CLICKED = 'registration_consciousness_levels_clicked',
  REGISTRATION_CONSCIOUSNESS_LEVELS_OPEN = 'registration_consciousness_levels_open',
  REGISTRATION_HOW_LONG_INTERESTED_OPEN = 'registration_how_long_interested_open',
  REGISTRATION_HOW_LONG_INTERESTED_CLICKED = 'registration_how_long_interested_clicked',
  REGISTRATION_KNOWLEDGE_SOURCES_OPEN = 'registration_knowledge_sources_open',
  REGISTRATION_KNOWLEDGE_SOURCES_CLICKED = 'registration_knowledge_sources_clicked',
  REGISTRATION_MATCH_TOPIC_OPEN = 'registration_match_topic_open',
  REGISTRATION_MATCH_TOPIC_CLICKED = 'registration_match_topic_clicked',
  SMS_INPUT_OPEN = 'sms_input_screen_open',
  REGISTRATION_WELCOME_BACK_OPEN = 'registration_welcome_back_open',

  SIMPLE_QUIZ_GENDER_OPEN = 'simple_quiz_gender_open',
  SIMPLE_QUIZ_GENDER_CLICK = 'simple_quiz_gender_click',
  SIMPLE_QUIZ_OPEN = 'simple_quiz_open',
  SIMPLE_QUIZ_CLICK = 'simple_quiz_click',
  SIMPLE_QUIZ_OPEN_OTHER = 'simple_quiz_open_other',
  SIMPLE_QUIZ_CLICK_OTHER = 'simple_quiz_click_other',
  UNIQUE_SKILLS_SIMPLE_QUIZ_OPEN = 'unique_skills_simple_quiz_open',
  UNIQUE_SKILLS_SIMPLE_QUIZ_CLICK = 'unique_skills_simple_quiz_click',
  BIRTH_DATE_SIMPLE_QUIZ_OPEN = 'birth_date_simple_quiz_open',
  BIRTH_DATE_SIMPLE_QUIZ_CLICK = 'birth_date_simple_quiz_click',
  REGCAT_SIMPLE_QUIZ_OPEN = 'regcat_simple_quiz_open',
  REGCAT_SIMPLE_QUIZ_CLICK = 'regcat_simple_quiz_click',
  REGFORM_SIMPLE_QUIZ_OPEN = 'regform_simple_quiz_open',
  REGFORM_SIMPLE_QUIZ_CLICK = 'regform_simple_quiz_click',

  REGISTRATION_EXPERTS_SCREEN_OPEN = 'registration_experts_screen_open',
  REGISTRATION_EXPERTS_SCREEN_CLICK = 'Registration_experts_screen_click',

  BLURRED_CATALOGUE_SCREEN_OPEN = 'blurred_catalogue_screen_open',
  BLURRED_CATALOGUE_SCREEN_CLICK = 'blurred_catalogue_screen_click ',

  REGISTRATION_RELATIONSHIP_STATUS_SCREEN_OPEN = 'registration_relationship_status_screen_open',
  REGISTRATION_RELATIONSHIP_STATUS_SCREEN_CLICK = 'registration_relationship_status_screen_click',
  REGISTRATION_PARENT_SCREEN_OPEN = 'registration_parent_screen_open',
  REGISTRATION_PARENT_SCREEN_CLICK = 'registration_parent_screen_click',
  REGISTRATION_SECOND_SECTION_START_SCREEN_OPEN = 'registration_second_section_start_screen_open',
  REGISTRATION_SECOND_SECTION_START_SCREEN_CLICK = 'registration_second_section_start_screen_click',
  REGISTRATION_ARE_YOU_HAPPY_SCREEN_OPEN = 'registration_are_you_happy_screen_open',
  REGISTRATION_ARE_YOU_HAPPY_SCREEN_CLICK = 'registration_are_you_happy_screen_click',
  REGISTRATION_WHAT_EMOTION_SCREEN_OPEN = 'registration_what_emotion_screen_open',
  REGISTRATION_WHAT_EMOTION_SCREEN_CLICK = 'registration_what_emotion_screen_click',
  REGISTRATION_WHAT_MISSING_SCREEN_OPEN = 'registration_what_missing_screen_open',
  REGISTRATION_WHAT_MISSING_SCREEN_CLICK = 'registration_what_missing_screen_click',
  REGISTRATION_RECOGNISING_SCREEN_OPEN = 'registration_recognising_screen_open',
  REGISTRATION_RECOGNISING_SCREEN_CLICK = 'registration_recognising_screen_click',
  REGISTRATION_DETAILED_GOAL_SCREEN_OPEN = 'registration_detailed_goal_screen_open',
  REGISTRATION_DETAILED_GOAL_SCREEN_CLICK = 'registration_detailed_goal_screen_click',
  REGISTRATION_DETAILED_GOAL_OTHER_OPEN = 'registration_detailed_goal_custom_form_open',
  REGISTRATION_DETAILED_GOAL_OTHER_CLICK = 'registration_detailed_goal_custom_form_click',
  REGISTRATION_ANXIOUS_SCREEN_OPEN = 'registration_anxious_screen_open',
  REGISTRATION_ANXIOUS_SCREEN_CLICK = 'registration_anxious_screen_click',
  REGISTRATION_IMPACT_DECISION_SCREEN_OPEN = 'registration_impact_decision_screen_open',
  REGISTRATION_IMPACT_DECISION_SCREEN_CLICK = 'registration_impact_decision_screen_click',
  REGISTRATION_HEAD_OR_HEART_SCREEN_OPEN = 'registration_head_or_heart_screen_open',
  REGISTRATION_HEAD_OR_HEART_SCREEN_CLICK = 'registration_head_or_heart_screen_click',
  REGISTRATION_CONFIDENCE_SCREEN_OPEN = 'registration_confidence_screen_open',
  REGISTRATION_CONFIDENCE_SCREEN_CLICK = 'registration_confidence_screen_click',
  REGISTRATION_QUESTIONS_UNCERTAINTY_SCREEN_OPEN = 'registration_questions_uncertainty_screen_open',
  REGISTRATION_QUESTIONS_UNCERTAINTY_SCREEN_CLICK = 'registration_questions_uncertainty_screen_click',
  REGISTRATION_SWIPE_LOVE_SCREEN_OPEN = 'registration_swipe_love_screen_open',
  REGISTRATION_SWIPE_LOVE_SCREEN_CLICK = 'registration_swipe_love_screen_click',
  REGISTRATION_SWIPE_LIFE_SCREEN_OPEN = 'registration_swipe_life_screen_open',
  REGISTRATION_SWIPE_LIFE_SCREEN_CLICK = 'registration_swipe_life_screen_click',
  REGISTRATION_SWIPE_FUTURE_SCREEN_OPEN = 'registration_swipe_future_screen_open',
  REGISTRATION_SWIPE_FUTURE_SCREEN_CLICK = 'registration_swipe_future_screen_click',
  REGISTRATION_THIRD_SECTION_START_SCREEN_OPEN = 'registration_third_section_start_screen_open',
  REGISTRATION_THIRD_SECTION_START_SCREEN_CLICK = 'registration_third_section_start_screen_click',
  REGISTRATION_CONSIDER_SCREEN_OPEN = 'registration_consider_screen_open',
  REGISTRATION_CONSIDER_START_SCREEN_CLICK = 'registration_consider_start_screen_click',
  REGISTRATION_LOOKING_TO_GAIN_SCREEN_OPEN = 'registration_looking_to_gain_screen_open',
  REGISTRATION_LOOKING_TO_GAIN_SCREEN_CLICK = 'registration_looking_to_gain_screen_click',
  REGISTRATION_READING_METHOD_SCREEN_OPEN = 'registration_reading_method_screen_open',
  REGISTRATION_READING_METHOD_SCREEN_CLICK = 'registration_reading_method_screen_click',
  REGISTRATION_METHOD_READING_SCREEN_OPEN = 'registration_method_reading_screen_open',
  REGISTRATION_METHOD_READING_SCREEN_CLICK = 'registration_method_reading_screen_click',
  REGISTRATION_MENTIONED_SCREEN_OPEN = 'registration_mentioned_screen_open',
  REGISTRATION_MENTIONED_SCREEN_CLICK = 'registration_mentioned_screen_click',
  REGISTRATION_NOT_ALONE_SCREEN_OPEN = 'registration_not_alone_screen_open',
  REGISTRATION_NOT_ALONE_SCREEN_CLICK = 'registration_not_alone_screen_click',
  REGISTRATION_EXPERTS_HERE_TO_HELP_SCREEN_OPEN = 'registration_experts_here_to_help_screen_open',
  REGISTRATION_EXPERTS_HERE_TO_HELP_SCREEN_CLICK = 'registration_experts_here_to_help_screen_click',
  REGISTRATION_WHAT_IS_PSYCHIC_READING_SCREEN_OPEN = 'registration_what_is_psychic_reading_screen_open',
  REGISTRATION_WHAT_IS_PSYCHIC_READING_SCREEN_CLICK = 'registration_what_is_psychic_reading_screen_click',
  REGISTRATION_SUMMARY_SCREEN_OPEN = 'registration_summary_screen_open',
  REGISTRATION_SUMMARY_SCREEN_CLICK = 'registration_summary_screen_click',
  REGISTRATION_ALWAY_KNOW_SCREEN_OPEN = 'registration_always_know_screen_open',
  REGISTRATION_ALWAY_KNOW_SCREEN_CLICK = 'registration_always_know_screen_click',
  REGISTRATION_OVERTHINKING_SCREEN_OPEN = 'registration_overthinking_screen_open',
  REGISTRATION_OVERTHINKING_SCREEN_CLICK = 'registration_overthinking_screen_click',
  REGISTRATION_OVERTHINKING_SCREEN_CLICK_CONTINUE = 'registration_overthinking_screen_click_continue',
  REGISTRATION_CRITICISM_SCREEN_OPEN = 'registration_criticism_screen_open',
  REGISTRATION_CRITICISM_SCREEN_CLICK = 'registration_criticism_screen_click',
  REGISTRATION_GOOD_ENOUGH_SCREEN_OPEN = 'registration_good_enough_screen_open',
  REGISTRATION_GOOD_ENOUGH_SCREEN_CLICK = 'registration_good_enough_screen_click',
  REGISTRATION_RELATE_STATEMENT_UNCERTAINTY_SCREEN_OPEN = 'registration_relate_statement_uncertainty_screen_open',
  REGISTRATION_RELATE_STATEMENT_UNCERTAINTY_SCREEN_CLICK = 'registration_relate_statement_uncertainty_screen_click',
  REGISTRATION_RELATE_STATEMENT_ANXIOUS_SCREEN_OPEN = 'registration_relate_statement_anxious_screen_open',
  REGISTRATION_RELATE_STATEMENT_ANXIOUS_SCREEN_CLICK = 'registration_relate_statement_anxious_screen_click',
  REGISTRATION_RELATE_STATEMENT_FUTURE_SCREEN_OPEN = 'registration_relate_statement_future_screen_open',
  REGISTRATION_RELATE_STATEMENT_FUTURE_SCREEN_CLICK = 'registration_relate_statement_future_screen_click',
  REGISTRATION_GUIDANCE_NEEDED_SCREEN_OPEN = 'registration_guidance_needed_screen_open',
  REGISTRATION_GUIDANCE_NEEDED_SCREEN_CLICK = 'registration_guidance_needed_screen_click',
  REGISTRATION_GUIDANCE_NEEDED_SCREEN_CLICK_CONTINUE = 'registration_guidance_needed_screen_click_continue',

  EXPERT_CATALOGUE_CHAT_SCREEN_OPEN = 'expert_catalogue_chat_screen_open',
  EXPERT_CATALOGUE_CHAT_SCREEN_CLICK = 'expert_catalogue_chat_screen_click',
  EXPERT_CATALOGUE_DRAFT_MESSAGE_CLICK = 'expert_catalogue_draft_message_click',
  EXPERT_CATALOGUE_EXPERT_AUTOCHANGE = 'expert_catalogue_expert_autochange',

  SIMPLE_CHAT_SCREEN_OPEN = 'simple_chat_screen_open',
  SIMPLE_CHAT_SCREEN_CLICK = 'simple_chat_screen_click',
  SIMPLE_DRAFT_MESSAGE_CLICK = 'simple_draft_message_click',
  SIMPLE_EXPERT_AUTOCHANGE = 'simple_expert_autochange',

  REGISTRATION_CONFIDENTIALITY_SCREEN_OPEN = 'registration_confidentiality_screen_open',
  REGISTRATION_CONFIDENTIALITY_SCREEN_CLICK = 'registration_confidentiality_screen_click',

  REGISTRATION_EMAILFORM_WHY_DO_WE_SAK_EMAIL_CLICK = 'registration_emailform_why_do_we_ask_email_click',
  REGISTRATION_WHY_DO_WE_ASK_EMAIL_SCREEN_OPEN = 'registration_why_do_we_ask_email_screen_open',
  REGISTRATION_WHY_DO_WE_ASK_EMAIL_SCREEN_CLICK = 'registration_why_do_we_ask_email_screen_click',
  REGISTRATION_NEW_GOALS_SCREEN_OPEN = 'registration_new_goals_screen_open',
  REGISTRATION_NEW_GOALS_SCREEN_CLICK = 'registration_new_goals_screen_click',

  REGISTRATION_NAME_SCREEN_OPEN = 'registration_name_screen_open',
  REGISTRATION_NAME_SCREEN_CLICK = 'registration_name_screen_click',

  REGISTRATION_CONSIDER_OTHER_OPEN = 'registration_consider_other_open',
  REGISTRATION_CONSIDER_START_OTHER_CLICK = 'registration_consider_start_other_click',
  REGISTRATION_ALWAYS_KNOW_SCREEN_OPEN = 'registration_always_know_screen_open',
  REGISTRATION_ALWAYS_KNOW_SCREEN_CLICK = 'registration_always_know_screen_click',

  REGISTRATION_ARE_YOU_HAPPY_SCREEN_CLICK_CONTINUE = 'registration_are_you_happy_screen_click_continue',
  REGISTRATION_WHAT_MISSING_SCREEN_CLICK_CONTINUE = 'registration_what_missing_screen_click_continue',
  REGISTRATION_ANXIOUS_SCREEN_CLICK_CONTINUE = 'registration_anxious_screen_click_continue',
  REGISTRATION_HEAD_OR_HEART_SCREEN_CLICK_CONTINUE = 'registration_head_or_heart_screen_click_continue',
  REGISTRATION_SPIRITUALITY_READING_SCREEN_CLICK_CONTINUE = 'registration_spirituality_reading_screen_click_continue',

  REGISTRATION_KNOW_PSYCHIC_READING_OPEN = 'registration_know_psychic_reading_open',
  REGISTRATION_KNOW_PSYCHIC_READING_CLICK = 'registration_know_psychic_reading_click',
  REGISTRATION_VARIOUS_TOPICS_OPEN = 'registration_various_topics_open',
  REGISTRATION_VARIOUS_TOPICS_CLICK = 'registration_various_topics_click',
  REGISTRATION_QUIZ_NEXT_STEPS_OPEN = 'registration_quiz_next_steps_open',
  REGISTRATION_QUIZ_NEXT_STEPS_CLICK = 'registration_quiz_next_steps_click',
  REGISTRATION_MESSAGE_ANIMATION_OPEN = 'registration_message_animation_open',
  REGISTRATION_MESSAGE_ANIMATION_CLICK = 'registration_message_animation_click',
}

export * from './BaseEvent';
export * from './BaseEventHandler';
