import { ReactNode } from 'react';
import { convertCredits } from 'src/core/common/entities';
import { FlowName } from 'src/core/quiz/entities';

const PER_MINUTE_USD_RATE = 3;
export const getExpertCreditsPerMinute = () => convertCredits(PER_MINUTE_USD_RATE);

export type ExpertId = string;

export type ExpertSpecialization = {
  id: number;
  name: string;
};

export type ExpertLanguage = {
  code: string;
  name: string;
  native_name: string;
};

export enum ExpertStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  BUSY = 'busy',
}

export type Expert = {
  id: ExpertId;
  name: string;
  status: ExpertStatus;
  focus: string;
  image: string;
  image_mini: string;
  slogan: string;
  description: string;
  experience: number;
  rating: number;
  review_count: number;
  total_orders: number;
  description_experience: string;
  feedback_count: number;
  languages: Array<ExpertLanguage>;
  specialization: string;
  specializations: Array<ExpertSpecialization>;
  chatPrice: number;
  chatPriceInCredits: number;
  offlineQuestionPrice: number;
  offlineQuestionPriceInCredits: number;
  isBestMatch?: boolean;
};

export type SimpleExpert = Pick<
  Expert,
  | 'id'
  | 'name'
  | 'image'
  | 'description'
  | 'experience'
  | 'rating'
  | 'review_count'
  | 'total_orders'
  | 'specializations'
>;

export enum Specializations {
  ASTROLOGY = 'Astrology',
  LIFE = 'Life',
  LOVE = 'Love Expert',
  ANGEL = 'Angel readings',
  CLAIRVOYANCE = 'Clairvoyance',
  NUMEROLOGY = 'Numerology',
  OCCULT = 'Occult readings',
  PSYCHIC = 'Psychic',
  PSYCHOLOGY = 'Psychology',
  SPIRITUALITY = 'Spirituality',
  TAROT = 'Tarot reading',
  VASTU = 'Vastu Consultant',
  MEDIUMSHIP = 'Mediumship',
  RUNES_READING = 'Runes reading',
  PENDULUM_READING = 'Pendulum reading',
  PAST_LIFE_READING = 'Past life reading',
  //custom psychics
  LOVE_READING = 'Love Reading',
  FORTUNE_TELLING = 'Fortune Telling',
  DREAM_ANALYSIS = 'Dream Analysis',
}

export type ExpertReviewProcessingStatus = 'not_published' | 'published';

export type GetExpertsReviewParamsSort =
  | 'customer_updated_at'
  | '-customer_updated_at'
  | 'processing_status'
  | '-processing_status'
  | 'rating'
  | '-rating'
  | 'status_active_livechats_rand';

export type ExpertReview = {
  astrologer?: {
    id: string;
    image: null | string;
    image_mini: string;
    name: string;
  };
  customer: {
    hex_bg_color?: string;
    name: string;
    src?: string;
    image?: ReactNode;
  };
  id: string;
  text: string;
  processing_status?: ExpertReviewProcessingStatus;
  rating: number;
  created_at: number;
};

export type ContextExpertReview = {
  astrologer?: {
    id: string;
    image: null | string;
    image_mini: string;
    name: string;
  };
  customer: {
    hex_bg_color?: string;
    name: string;
    isTrusted?: boolean;
    age: number;
    src?: string;
    image?: ReactNode;
  };
  id: string;
  text: string;
  processing_status?: ExpertReviewProcessingStatus;
  rating: number;
  created_at: number;
};

export type GetExpertReviewsParams = Partial<{
  filter: Partial<{
    processingStatus: ExpertReviewProcessingStatus;
    expertId: string;
    customerSource: string;
    customerSourceId: string;
    rating: number;
    withText: boolean;
    withoutText: boolean;
  }>;
  sort: GetExpertsReviewParamsSort;
  page: number;
  per_page: number;
}>;

export type ExpertRequestFilter = Partial<{
  'only-trial': 1 | 0;
}>;

export type ExpertsRequestParams = Partial<{
  perPage: number;
  page: number;
  customerSource: FlowName | 'seo';
  filter: ExpertRequestFilter;
}>;

export enum ExpertCategory {
  MOST_ACCURATE = 'most_accurate',
  LOVE_READINGS = 'love_readings',
  OF_THE_DAY = 'of_the_day',
  RECOMMENDED = 'recommended_for_you',
  TOP_RATED = 'top_rated',
  ALL = 'all',
  LOVE_AND_RELATIONSHIP = 'love_and_relationship',
  DESTINY_AND_LIFE_PATH = 'destiny_and_life_path',
  CAREER_AND_WORK = 'career_and_work',
  FUTURE_TELLING = 'future_telling',
  FAMILY_AND_FRIENDS = 'family_and_friends',
  SPIRIT_GUIDANCE = 'spirit_guidance',
}

export function getExpertTotalReviewCount(expert: Expert): number {
  const { feedback_count = 0, review_count = 0 } = expert;

  return feedback_count + review_count;
}
