import { createContext, ReactNode } from 'react';
import { v4 as uuid } from 'uuid';
import { chatEventHandlers } from 'src/core/chat/analytics/events';
import { checkIsClientSide } from 'src/core/common/utils/checkIsClientSide';
import {
  UserService,
  UtmTagsClientService,
  UtmTagsServerService,
  UtmTagsService,
} from 'src/core/user/services';
import { ChatBotService } from 'src/core/chat-bot/services';
import { HttpExpertService } from 'src/core/experts/services';
import { ExpertService } from 'src/core/experts/interfaces';
import { config } from 'src/config';
import {
  LocationClientService,
  LocationServerService,
  LocationService,
} from 'src/core/location/services';
import {
  CookiesIdFeatureFlagsDecorator,
  FeatureFlags,
  GrowthBookFeatureFlags,
  QuerySettingFeatureFlagsDecorator,
} from 'src/core/feature-flags/services';
import {
  featureFlagsConfig,
  SessionRecorderExperimentGroups,
  sessionRecorderExperimentName,
} from 'src/core/feature-flags/config';
import { ThemeClientService, ThemeServerService, ThemeService } from 'src/core/theme/services';
import {
  AmplitudeClientService,
  AnalyticsClientService,
  AnalyticsServerService,
  AnalyticsService,
  BackendAnalyticsService,
  ConversionsAPIService,
  EverflowService,
  FacebookService,
  GoogleAnalyticsService,
  GTMClientService,
} from 'src/core/analytics/services';
import { PaymentService } from 'src/core/payments/services';
import { ChatService } from 'src/core/chat/services';
import { AuthClientService, AuthServerService, AuthService } from 'src/core/auth/services';
import {
  AppEnvironmentClientService,
  AppEnvironmentServerService,
  AppEnvironmentService,
  CookiesService,
  createHttpClient,
  CrossDomainStorage,
  GeoService,
  PersistentStorageClientService,
  PersistentStorageServerService,
  PersistentStorageService,
  SessionRecorder,
  WithRequestId,
} from 'src/core/common/services';
import { HoroscopeService } from 'src/core/horoscope/services';
import { homePageEventHandlers } from 'src/home/analytics/events';
import { psychicsPageEventHandlers } from 'src/psychics/analytics/events';
import { psychicsCategoriesPageEventHandlers } from 'src/psychics-categories/analytics/events';
import { BaseEventHandler } from 'src/core/analytics/events';
import { featureFlagsEventHandlers } from 'src/core/feature-flags/events';
import { glossaryPageEventHandlers } from 'src/glossary/analytics/events';
import { advisorSinglePageEventHandlers } from 'src/advisors/analytics/events';
import { expertCatalogueEventHandlers } from 'src/experts-catalogue/analytics/events';
import {
  CookieConsentService,
  OneTrustCookieConsentService,
} from 'src/core/common/services/cookieConsent';
import { contactUsPageEventHandlers } from 'src/contact-us/analytics/events';
import { supportPageEventHandlers } from 'src/support/analytics/events';
import { faqPageEventHandlers } from 'src/faq/analytics/events';
import { simpleRegistrationEventHandlers } from 'src/simple-registration/analytics/events';
import { zodiacTraitsPagesEventHandlers } from 'src/zodiac-traits/analytics/events';
import { blogPageEventHandlers } from 'src/blog/analytics/events';
import { contentManagerEventHandlers } from 'src/core/contentManager/analytics/events';
import {
  BaseLogsBatchCache,
  LogsBatchCacheWithLocalStorage,
} from 'src/core/common/logger/services';
import { Logger } from 'src/core/common/logger/interfaces';
import { becomeAPsychicPageEventHandlers } from 'src/become-a-psychic/analytics/events';
import { chartPageEventHandlers } from 'src/charts/analytics/events';
import { authEventHandlers } from 'src/core/auth/analytics/events';
import { nativeAppPageEventHandlers } from 'src/native-app/analytics/events';
import { horoscopePageEventHandlers } from 'src/horoscope/analytics/events';
import { reviewsPageEventHandlers } from 'src/reviews/analytics/events';
import { expertsEventHandlers } from 'src/core/experts/analytics/events';
import { pressPageEventHandlers } from 'src/press/analytics/events';
import { zodiacCompatibilityPagesEventHandlers } from 'src/zodiac/analytics/events';
import { LoggerBuilder } from 'src/core/common/logger/services/LoggerBuilder';
import {
  GrafanaLoggerBatchDestination,
  GrafanaLoggerService,
} from 'src/core/common/grafana-logger/services';
import {
  ClarityHeatmapRecordingService,
  ClarityHeatmapRecordingClientService,
  ClarityHeatmapRecordingServerService,
} from 'src/core/common/services/ClarityHeatmapRecordingService';
import { BrowserAppResource } from 'src/core/common/observability/entities';
import { OTelObservabilitySystemBuilder } from 'src/core/common/observability/services';
import { ObservabilitySystem } from 'src/core/common/observability/interfaces';

export interface Services {
  appEnvironmentService: AppEnvironmentService;
  analyticsService: AnalyticsService;
  paymentService: PaymentService;
  expertService: ExpertService;
  authService: AuthService;
  logger: Logger;
  cookiesService: CookiesService;
  crossDomainStorage: CrossDomainStorage;
  cookieConsentService: CookieConsentService;
  chatBotService: ChatBotService;
  geoService: GeoService;
  userService: UserService;
  sessionRecorder: SessionRecorder;
  utmTagsService: UtmTagsService;
  locationService: LocationService;
  chatService: ChatService;
  featureFlags: FeatureFlags;
  persistentStorageService: PersistentStorageService;
  themeService: ThemeService;
  horoscopeService: HoroscopeService;
  clarityHeatmapRecordingService: ClarityHeatmapRecordingService;
  observabilitySystem: ObservabilitySystem;
}

export const setupSessionRecorder = async ({
  sessionRecorder,
  appEnvironmentService,
  featureFlags,
}: {
  sessionRecorder: SessionRecorder;
  featureFlags: FeatureFlags;
  appEnvironmentService: AppEnvironmentService;
}) => {
  const experimentGroup = featureFlags.getExperimentGroup(sessionRecorderExperimentName);
  const shouldRecord = experimentGroup === SessionRecorderExperimentGroups.RECORD_ON;

  if (!appEnvironmentService.isProductionEnv() || !shouldRecord) return;

  const randomId = uuid();
  sessionRecorder.identifyUser(randomId);
  sessionRecorder.setShouldRecord(true);
};

export const initServices = (): Services => {
  const services = checkIsClientSide() ? initClientServices() : initServerServices();

  return { ...services };
};

const initServerServices = (): Services => {
  const logger = new LoggerBuilder().getResult();

  const cookiesService = new CookiesService();
  const crossDomainStorage = new CrossDomainStorage(config);
  const cookieConsentService = new OneTrustCookieConsentService(config, logger);
  const clarityHeatmapRecordingService = new ClarityHeatmapRecordingServerService();
  const appEnvironmentService = new AppEnvironmentServerService(config, logger, cookiesService);
  const httpClient = new WithRequestId(
    createHttpClient({
      apiUrl: config.apiUrl,
      apiPrefix: config.apiPrefix,
    }),
  );
  const observabilitySystem = OTelObservabilitySystemBuilder.create(config).getResult();

  const paymentService = new PaymentService(httpClient);
  const expertService = new HttpExpertService(httpClient);
  const userService = new UserService(httpClient);
  const utmTagsService = new UtmTagsServerService();
  const chatBotService = new ChatBotService();
  const chatService = new ChatService(httpClient);
  const locationService = new LocationServerService();

  const geoService = new GeoService(
    process.env.NEXT_PUBLIC_GEOCODE_URL || '',
    process.env.NEXT_PUBLIC_GEOCODE_API_KEY || '',
  );
  const authService = new AuthServerService(httpClient, crossDomainStorage, cookiesService);
  const analyticsService = new AnalyticsServerService(logger);

  const featureFlags = new GrowthBookFeatureFlags({}, appEnvironmentService, config, logger);

  const sessionRecorder = new SessionRecorder(
    config.smartlook.apiKey,
    utmTagsService,
    featureFlags,
  );

  const persistentStorageService = new PersistentStorageServerService(logger);
  const themeService = new ThemeServerService(persistentStorageService, logger);
  const horoscopeService = new HoroscopeService(httpClient, logger);

  return {
    appEnvironmentService,
    logger,
    cookiesService,
    crossDomainStorage,
    analyticsService,
    paymentService,
    expertService,
    utmTagsService,
    authService,
    chatBotService,
    geoService,
    userService,
    sessionRecorder,
    chatService,
    locationService,
    featureFlags,
    persistentStorageService,
    themeService,
    horoscopeService,
    cookieConsentService,
    observabilitySystem,
    clarityHeatmapRecordingService,
  };
};

const initClientServices = (): Services => {
  const logger = new LoggerBuilder()
    .addConsoleTransport()
    .addBatchTransport(
      new GrafanaLoggerBatchDestination(
        new GrafanaLoggerService(
          createHttpClient({
            apiUrl: config.grafanaLogger.url,
          }),
        ),
      ),
      new LogsBatchCacheWithLocalStorage(new BaseLogsBatchCache()),
    )
    .addContext({ context: 'quiz' })
    .getResult();

  const httpClient = new WithRequestId(
    createHttpClient({
      apiUrl: config.apiUrl,
      apiPrefix: config.apiPrefix,
    }),
  );

  const cookiesService = new CookiesService({ domain: config.domainRoot });
  const crossDomainStorage = new CrossDomainStorage(config);
  const cookieConsentService = new OneTrustCookieConsentService(config, logger);
  const appEnvironmentService = new AppEnvironmentClientService(
    config,
    crossDomainStorage,
    cookiesService,
  );
  const clarityHeatmapRecordingService = new ClarityHeatmapRecordingClientService(logger);

  const growthBookFeatureFlags = new GrowthBookFeatureFlags(
    featureFlagsConfig,
    appEnvironmentService,
    config,
    logger,
  );
  growthBookFeatureFlags.loadFeatures();

  const resource = BrowserAppResource.create();

  resource.setAppName(config.appName).setEnv(config.env);

  const observabilitySystemBuilder = OTelObservabilitySystemBuilder.create(config)
    .addBrowserTraceProvider({ resource })
    .withSplitId(appEnvironmentService)
    .addXMLHttpRequestInstrumentation({
      ignoreUrls: config.observability.httpInstrumentationIgnoreUrls,
      spanRate: config.observability.httpInstrumentationSpanRate,
    })
    .addDocumentLoadedInstrumentation()
    .withHttpSpanExporter({
      url: config.observability.tracesUrl,
      headers: {},
    });

  const observabilitySystem = observabilitySystemBuilder.getResult();

  const featureFlagsWithIdentify = new CookiesIdFeatureFlagsDecorator(
    growthBookFeatureFlags,
    cookiesService,
    config,
    logger,
  );
  const featureFlagsWithQuerySetting = new QuerySettingFeatureFlagsDecorator(
    featureFlagsWithIdentify,
  );

  const utmTagsService = UtmTagsClientService.crateFromQueryParams(crossDomainStorage, logger);

  const paymentService = new PaymentService(httpClient);
  const expertService = new HttpExpertService(httpClient);
  const userService = new UserService(httpClient);
  const amplitudeService = new AmplitudeClientService(utmTagsService, config, observabilitySystem);
  const gtmService = new GTMClientService();
  const conversionsAPIService = new ConversionsAPIService();
  const facebookService = new FacebookService(logger);
  const chatBotService = new ChatBotService();
  const googleAnalyticsService = new GoogleAnalyticsService(
    createHttpClient({
      apiUrl: config.googleAnalytics.apiUrl,
    }),
    logger,
    utmTagsService,
    cookiesService,
    observabilitySystem,
  );
  const backendAnalyticsService = new BackendAnalyticsService(
    httpClient,
    logger,
    observabilitySystem,
  );
  const everflowService = new EverflowService(utmTagsService, cookiesService, observabilitySystem);
  const chatService = new ChatService(httpClient);
  const locationService = new LocationClientService(httpClient, logger);

  const geoService = new GeoService(
    process.env.NEXT_PUBLIC_GEOCODE_URL || '',
    process.env.NEXT_PUBLIC_GEOCODE_API_KEY || '',
  );

  const sessionRecorder = new SessionRecorder(
    config.smartlook.apiKey,
    utmTagsService,
    featureFlagsWithQuerySetting,
  );
  setupSessionRecorder({
    sessionRecorder,
    featureFlags: featureFlagsWithQuerySetting,
    appEnvironmentService,
  });

  const authService = new AuthClientService(httpClient, cookiesService, crossDomainStorage);

  const handlers: BaseEventHandler[] = [
    ...authEventHandlers,
    ...expertsEventHandlers,
    ...homePageEventHandlers,
    ...expertCatalogueEventHandlers,
    ...psychicsPageEventHandlers,
    ...psychicsCategoriesPageEventHandlers,
    ...glossaryPageEventHandlers,
    ...featureFlagsEventHandlers,
    ...advisorSinglePageEventHandlers,
    ...contactUsPageEventHandlers,
    ...supportPageEventHandlers,
    ...faqPageEventHandlers,
    ...chatEventHandlers,
    ...simpleRegistrationEventHandlers,
    ...zodiacTraitsPagesEventHandlers,
    ...blogPageEventHandlers,
    ...contentManagerEventHandlers,
    ...becomeAPsychicPageEventHandlers,
    ...chartPageEventHandlers,
    ...nativeAppPageEventHandlers,
    ...horoscopePageEventHandlers,
    ...reviewsPageEventHandlers,
    ...pressPageEventHandlers,
    ...zodiacCompatibilityPagesEventHandlers,
  ].map(
    (Handler) =>
      new Handler({
        amplitudeService,
        googleAnalyticsService,
        gtmService,
        facebookService,
        backendAnalyticsService,
        sessionRecorder,
        logger,
      }),
  );

  const analyticsService = new AnalyticsClientService(
    amplitudeService,
    gtmService,
    conversionsAPIService,
    facebookService,
    googleAnalyticsService,
    backendAnalyticsService,
    everflowService,
    logger,
    sessionRecorder,
    handlers,
  );

  featureFlagsWithQuerySetting.onTrackExperiment((experiment, result) => {
    analyticsService.setUserExperimentGroupName(experiment.key, result.value);
  });

  const persistentStorageService = new PersistentStorageClientService(logger);
  const themeService = new ThemeClientService(persistentStorageService, logger);
  const horoscopeService = new HoroscopeService(httpClient, logger);

  return {
    appEnvironmentService,
    logger,
    cookiesService,
    crossDomainStorage,
    analyticsService,
    paymentService,
    expertService,
    authService,
    chatBotService,
    sessionRecorder,
    geoService,
    userService,
    utmTagsService,
    chatService,
    locationService,
    featureFlags: featureFlagsWithQuerySetting,
    persistentStorageService,
    themeService,
    horoscopeService,
    cookieConsentService,
    observabilitySystem,
    clarityHeatmapRecordingService,
  };
};

export const ServicesContext = createContext<Services>(undefined!);

type ServicesProviderProps = {
  value: Services;
  children: ReactNode;
};

const ServicesProvider = ({ value, children }: ServicesProviderProps) => {
  return <ServicesContext.Provider value={value}>{children}</ServicesContext.Provider>;
};

export default ServicesProvider;
